<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <notifications></notifications>
      <login-card header-color="cyan">
        <h4 slot="title" class="title">
          Welcome To Avalia Assess
        </h4>
        <div slot="inputs" class="signin_container">
          <div id="signin_button"></div>
        </div>
        <template v-if="isExternalUsersFeatureEnabled">
          <md-field slot="inputs" class="md-form-group">
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input
              v-model="email"
              type="email"
              ref="emailField"
              @keydown.enter="authenticate"
            />
          </md-field>
          <md-field slot="inputs" class="md-form-group">
            <md-icon>lock_outline</md-icon>
            <label>Password...</label>
            <md-input
              v-model="password"
              type="password"
              @keydown.enter="authenticate"
            />
          </md-field>

          <md-button
            slot="footer"
            class="md-simple md-info md-lg"
            @click.native="authenticate"
          >
            Connect
          </md-button>
        </template>
      </login-card>
    </div>
  </div>
</template>
<script>

import { LoginCard } from '@/components/Template';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      email: null,
      password: null,
      googleSignInLibrary: null,
    };
  },
  computed: {
    isExternalUsersFeatureEnabled() {
      return this.$store.getters['config/GET_CORE_CONFIG'].FF_EXTERNAL_USERS;
    },
  },
  // eslint-disable-next-line object-shorthand
  mounted() {
    this.googleSignInLibrary = document.getElementById('google-signin-library');

    if (window.google) {
      this.setupGoogleSignIn();
    } else {
      this.googleSignInLibrary.addEventListener('load', this.setupGoogleSignIn);
    }
  },
  unmounted() {
    this.googleSignInLibrary.removeEventListener('load', this.setupGoogleSignIn);
  },
  methods: {
    setupGoogleSignIn() {
      const propName = 'google.oauth.client.id';
      const propValue = this.$store.getters['config/GET_CORE_CONFIG'][propName];
      window.google.accounts.id.initialize({
        client_id: propValue,
        callback: this.onGoogleSigninSuccess,
      });
      // If One Tap prompt is required
      //window.google.accounts.id.prompt();
      window.google.accounts.id.renderButton(
        document.getElementById('signin_button'),
        {
          theme: 'outline', size: 'large', width: 290, text: 'signin_with', locale: 'en_CH',
        },
      );
    },
    onGoogleSigninSuccess(googleUser) {
      const googleToken = googleUser.credential;
      api.assess.post('/auth/googleToAvaliaJWT', { googleToken })
        .then((response) => {
          this.errorMessage = '';
          return this.$store.dispatch('users/login', response.data.token);
        })
        .then(() => {
          // Redirect user to playbook webapp if he is a community member
          const [domain] = this.$store.getters['users/GET_CURRENT_USER'].sub.split('@').slice(-1);
          if (this.$store.getters['config/GET_CORE_CONFIG']['avalia.oauth.external.hosted.domains']
            .find((communityDomain) => communityDomain === domain)) {
            window.location.href = process.env.VUE_APP_PLAYBOOK_FRONTEND_URL;
            return;
          }

          this.$router.push('/projects');
        })
        .catch((error) => {
          const errorMessage = `Login failed : ${error.response.data}`;
          notify.notifyError(errorMessage, this);
        });
    },
    authenticate() {
      api.idm
        .post('/api/public/auth', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.errorMessage = '';
          this.$store.dispatch('users/login', response.data.token);
          this.$router.push('/projects');
        })
        .catch((error) => {
          const errorMessage = `Authentication failed : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.google-button {
  justify-content: center;
  display: flex;
}

.top-padding {
    padding-top: 15px;
}

</style>
